export const transferEn = {
  'transfer-heading': 'Transfers',
  'transfer-make-transfer-title': 'Between Accounts',
  'transfer-to-my-account-text': 'To My Bank Account',
  'transfer-balance-label': 'Available Balance:',
  'transfer-send-money-title': 'Send Money',
  'transfer-account-to-account-text': 'Card to Card',
  'transfer-person-to-person-text': 'Person to Person',
  'transfer-not-enabled-alert':
    'Transfers have not been enabled for your accounts\' program at this time.',
  'transfer-person-to-external-title': 'Request Money',
  'transfer-request-money-title': 'Request Money',
  'transfer-person-to-external-text': 'From External Contact',
  'transfer-etransfer-push-text': 'Interac e-Transfer',
  'transfer-etransfer-pull-text': 'Interac e-Transfer',
  'transfer-directsend-push-text': 'Push to an account using Debit Card',
  'transfer-directsend-pull-text': 'Pull from an account using Debit Card',
};

export const transferFr = {
  'transfer-heading': 'Transferts',
  'transfer-make-transfer-title': 'Entre les comptes',
  'transfer-to-my-account-text': 'Vers mon compte bancaire',
  'transfer-balance-label': 'Solde disponible:',
  'transfer-send-money-title': 'Envoyer de l\'argent',
  'transfer-account-to-account-text': 'Carte à carte',
  'transfer-person-to-person-text': 'Personne à personne',
  'transfer-not-enabled-alert':
    'Les transferts n\'ont pas été activés pour le programme de vos comptes pour le moment.',
  'transfer-person-to-external-title': 'Demander de l\'argent',
  'transfer-request-money-title': 'Demander de l\'argent',
  'transfer-person-to-external-text': 'Personne externe',
  'transfer-etransfer-push-text': 'Interac virement électronique',
  'transfer-etransfer-pull-text': 'Interac virement électronique',
  'transfer-directsend-push-text': 'Envoyer vers un compte en utilisant une carte de débit',
  'transfer-directsend-pull-text': 'Tirer depuis un compte en utilisant une carte de débit',
};
