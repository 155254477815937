export const directsendPullEn = {
  'directsend-pull-heading': 'Request Funds',
  'directsend-pull-add-contact-alert':
    'No Contacts found, please add a new contact to continue',
  'directsend-pull-new-contact-button': 'Add Contact',
  'directsend-pull-create-contact-heading': 'Add Contact',
  'directsend-pull-update-contact-heading': 'Update Contact',
  'directsend-pull-delete-heading': 'Delete Contact',
  'directsend-pull-3d-secure-heading': '3D Secure Authentication',
  'directsend-pull-contact-name-input-label': 'External Account Name',
  'directsend-pull-contact-full-cardholder-name-input-label':
    'Full Cardholder Name',
  'directsend-pull-contact-card-number-input-label': 'Card Number',
  'directsend-pull-contact-expiry-input-label': 'Expiry',
  'directsend-pull-contact-cvv-input-label': 'CVV',
  'directsend-pull-contact-address-line-one-input-label': 'Address Line 1',
  'directsend-pull-contact-address-line-two-input-label': 'Address Line 2',
  'directsend-pull-contact-city-input-label': 'City',
  'directsend-pull-contact-state-input-label': 'Province/State',
  'directsend-pull-contact-postal-code-input-label': 'Postal Code',
  'directsend-pull-contact-country-input-label': 'Country',
  'directsend-pull-create-contact': 'add Contact',
  'directsend-pull-success-create-alert': 'Contact successfully added',
  'directsend-pull-account-deleted-alert': 'Contact successfully deleted',
  'directsend-pull-success-update-alert': 'Contact successfully updated',
  'directsend-pull-3ds-authentication-failed-alert':
    '3D Secure authentication failed',
  'directsend-pull-3ds-authenticated-timeout-alert':
    '3D Secure challenge submission was not received. Please try again later',
  'directsend-pull-3ds-authenticated-alert':
    '3D Secure authentication completed successfully. Proceeding with the transaction',
  'directsend-pull-form-title': 'Request Funds to my Card',
  'directsend-pull-form-text': 'From External Contact with Debit Card',
  'directsend-pull-delete-form-text':
    'Are you sure you want to delete your contact - {name} ',
  'directsend-pull-form-select-external-account-label':
    'Select a contact to request funds from',
  'directsend-pull-amount-label': 'Amount',
  'directsend-pull-exceeds-available-amount-alert':
    'Amount exceeds available balance',
  'directsend-pull-transfer-created-alert': 'Funds requested successfully',
  'directsend-pull-confirm-transfer-heading': 'Confirm Transfer',
  'directsend-pull-message-label': 'Message',
  'directsend-pull-confirm-transfer-message':
    'This transfer has a fee of ${feeAmount}. Do you wish to continue with this funds request?', // eslint-disable-line no-template-curly-in-string
  'directsend-pull-confirm-transfer-no-fee-message':
    'Do you wish to continue with this funds request?',
};

export const directsendPullFr = {
  'directsend-pull-heading': 'Demander des fonds',
  'directsend-pull-add-contact-alert':
    'Aucun contact trouvé, veuillez ajouter un nouveau contact pour continuer',
  'directsend-pull-new-contact-button': 'Ajouter un contact',
  'directsend-pull-contact-name-input-label': 'Nom de compte externe',
  'directsend-pull-contact-full-cardholder-name-input-label':
    'Nom complet du titulaire de la carte',
  'directsend-pull-contact-card-number-input-label': 'Numéro de carte',
  'directsend-pull-contact-expiry-input-label': 'Expiration',
  'directsend-pull-contact-cvv-input-label': 'CVV',
  'directsend-pull-contact-address-line-one-input-label': 'Adresse ligne 1',
  'directsend-pull-contact-address-line-two-input-label': 'Adresse ligne 2',
  'directsend-pull-contact-city-input-label': 'Ville',
  'directsend-pull-contact-state-input-label': 'Province/État',
  'directsend-pull-contact-postal-code-input-label': 'Code postal',
  'directsend-pull-contact-country-input-label': 'Pays',
  'directsend-pull-create-contact-heading': 'Ajouter un contact',
  'directsend-pull-update-contact-heading': 'Mettre à jour le contact',
  'directsend-pull-delete-heading': 'Supprimer le contact',
  'directsend-pull-3d-secure-heading': 'Authentification sécurisée 3D',
  'directsend-pull-create-contact': 'Ajouter un contact',
  'directsend-pull-success-create-alert': 'Contact ajouté avec succès',
  'directsend-pull-account-deleted-alert': 'Contact supprimé avec succès',
  'directsend-pull-success-update-alert': 'Contact mis à jour avec succès',
  'directsend-pull-3ds-authenticated-timeout-alert':
    'La soumission du défi 3D Secure n\'a pas été reçue. Veuillez réessayer plus tard',
  'directsend-pull-3ds-authentication-failed-alert':
    'Authentification 3D Secure échouée',
  'directsend-pull-3ds-authenticated-alert':
    'Authentification 3D Secure terminée avec succès. Poursuite de la transaction',
  'directsend-pull-form-title': 'Demander des fonds vers ma carte',
  'directsend-pull-form-text': 'De contact externe avec une carte de débit',
  'directsend-pull-delete-form-text':
    'Êtes-vous sûr de vouloir supprimer votre contact - {name} ?',
  'directsend-pull-form-select-external-account-label':
    'Sélectionnez un contact à qui demander des fonds',
  'directsend-pull-amount-label': 'Montant',
  'directsend-pull-exceeds-available-amount-alert':
    'Le montant dépasse le solde disponible',
  'directsend-pull-transfer-created-alert': 'Fonds demandés avec succès',
  'directsend-pull-confirm-transfer-heading': 'Confirmer le transfert',
  'directsend-pull-message-label': 'Message',
  'directsend-pull-confirm-transfer-message':
    'Ce virement comporte des frais de ${feeAmount}. Souhaitez-vous continuer avec cette demande de fonds?', // eslint-disable-line no-template-curly-in-string
  'directsend-pull-confirm-transfer-no-fee-message':
    'Souhaitez-vous continuer avec cette demande de fonds?',
};
