import React from 'react';
import PropTypes from 'prop-types';
// Import Local Components
import MyBankAccountIcon from './icons/c2a-icon.png';
import CardToCardIcon from './icons/c2c-icon.png';
import PersonToPersonIcon from './icons/p2p-icon.png';
import PersonToExternalIcon from './icons/p2e-icon.png';
import MyBankAccountToCardIcon from './icons/a2c-icon.png';
// Import Component Styles
import { Card, Button, Text } from './styles';

const TransferTypeButton = ({ text, textFor, onClick }) => {
  const imgStyle = { width: '125px' };

  // eslint-disable-next-line
  const renderIcon = () => {
    switch (textFor) {
    case 'to-my-account':
      return (
        <img
          style={imgStyle}
          src={MyBankAccountIcon}
          alt="To My Account Icon"
        />
      );
    case 'card-to-card':
      return (
        <img style={imgStyle} src={CardToCardIcon} alt="Card to Card Icon" />
      );
    case 'person-to-person':
      return (
        <img
          style={imgStyle}
          src={PersonToPersonIcon}
          alt="Person to Person Icon"
        />
      );
    case 'etransfer-push':
      return (
        <img
          style={imgStyle}
          src={PersonToPersonIcon}
          alt="Etransfer Push Icon"
        />
      );
    case 'etransfer-pull':
      return (
        <img
          style={imgStyle}
          src={PersonToExternalIcon}
          alt="Etransfer Pull Icon"
        />
      );
    case 'directsend-push':
      return (
        <img
          style={imgStyle}
          src={MyBankAccountIcon}
          alt="Directsend Push Icon"
        />
      );
    case 'directsend-pull':
      return (
        <img
          style={imgStyle}
          src={MyBankAccountToCardIcon}
          alt="Directsend Pull Icon"
        />
      );
    case 'person-to-external':
      return (
        <img
          style={imgStyle}
          src={PersonToExternalIcon}
          alt="Person to External Icon"
        />
      );

    default:
      break;
    }
  };

  return (
    <Button variant="text" onClick={onClick}>
      <Card>
        {renderIcon()}
        <Text text={text} textFor={textFor} page="transfer" />
      </Card>
    </Button>
  );
};

TransferTypeButton.propTypes = {
  text: PropTypes.string.isRequired,
  textFor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TransferTypeButton;
