import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { ButtonGroup } from 'shared-components';
// Import Global Components
import Title from '../../../../components/Title';
import Heading from '../../../../components/Heading';
import Alert from '../../../../components/Alert';
// Import Local Components
import TransferTypeButton from '../../components/TransferTypeButton';
// Import Component Styles
import { Section } from './styles';

const SelectTransfer = ({ history, match: { path }, configs }) => {
  const intl = useIntl();
  const page = 'transfer';

  const transferConfigKeys = [
    'allow_personal_bank_account_transfers',
    'allow_person_to_person_transfers',
    'allow_account_to_account_transfer',
    'allow_child_cards',
    'allow_interac_pull_transfers',
    'allow_external_transfers_v2',
  ];
  // Returns an array of boolean values for transfer configurations
  const transferConfigValues = transferConfigKeys.map(key => configs[key]);

  return (
    <>
      <Heading page={page} heading={intl.messages[`${page}-heading`]} />
      {transferConfigValues.every(value => value === false) ? (
        <Alert
          alert={intl.messages[`${page}-not-enabled-alert`]}
          type="info"
          alertFor="not-enabled"
          page={page}
        />
      ) : (
        <>
          {configs.allow_personal_bank_account_transfers && (
            <Section>
              <Title
                title={intl.messages[`${page}-make-transfer-title`]}
                titleFor="make-transfer"
                page={page}
              />
              <ButtonGroup justifyContent="flex-start">
                <TransferTypeButton
                  text={intl.messages[`${page}-to-my-account-text`]}
                  textFor="to-my-account"
                  onClick={e => {
                    e.preventDefault();
                    history.push(`${path}/my-account`);
                  }}
                />
              </ButtonGroup>
            </Section>
          )}
          {(configs.allow_account_to_account_transfer ||
            configs.allow_child_cards ||
            configs.allow_person_to_person_transfers ||
            configs.allow_external_transfers_v2) && (
            <Section>
              <Title
                title={intl.messages[`${page}-send-money-title`]}
                titleFor="send-money"
                page={page}
              />
              <ButtonGroup justifyContent="flex-start">
                {(configs.allow_account_to_account_transfer ||
                  configs.allow_child_cards) && (
                  <TransferTypeButton
                    text={intl.messages[`${page}-account-to-account-text`]}
                    textFor="card-to-card"
                    onClick={e => {
                      e.preventDefault();
                      history.push(`${path}/card-to-card`);
                    }}
                  />
                )}
                {configs.allow_person_to_person_transfers && (
                  <TransferTypeButton
                    text={intl.messages[`${page}-person-to-person-text`]}
                    textFor="person-to-person"
                    onClick={e => {
                      e.preventDefault();
                      history.push(`${path}/person-to-person`);
                    }}
                  />
                )}
                {configs.allow_external_transfers_v2 && (
                  <>
                    <TransferTypeButton
                      text={intl.messages[`${page}-etransfer-push-text`]}
                      textFor="etransfer-push"
                      onClick={e => {
                        e.preventDefault();
                        history.push(`${path}/etransfer-push`);
                      }}
                    />
                    <TransferTypeButton
                      text={intl.messages[`${page}-directsend-push-text`]}
                      textFor="directsend-push"
                      onClick={e => {
                        e.preventDefault();
                        history.push(`${path}/directsend-push`);
                      }}
                    />
                  </>
                )}
              </ButtonGroup>
            </Section>
          )}
          {(configs.allow_interac_pull_transfers || configs.allow_external_transfers_v2) && (
            <Section>
              <Title
                title={intl.messages[`${page}-request-money-title`]}
                titleFor="request-money"
                page={page}
              />
              <ButtonGroup justifyContent="flex-start">
                {configs.allow_external_transfers_v2 && (
                  <>
                    <TransferTypeButton
                      text={intl.messages[`${page}-etransfer-pull-text`]}
                      textFor="etransfer-pull"
                      onClick={e => {
                        e.preventDefault();
                        history.push(`${path}/etransfer-pull`);
                      }}
                    />
                    <TransferTypeButton
                      text={intl.messages[`${page}-directsend-pull-text`]}
                      textFor="directsend-pull"
                      onClick={e => {
                        e.preventDefault();
                        history.push(`${path}/directsend-pull`);
                      }}
                    />
                  </>
                )}
                {configs.allow_interac_pull_transfers && (
                  <TransferTypeButton
                    text={intl.messages[`${page}-person-to-external-text`]}
                    textFor="person-to-external"
                    onClick={e => {
                      e.preventDefault();
                      history.push(`${path}/person-to-external`);
                    }}
                  />
                )}
              </ButtonGroup>
            </Section>
          )}
        </>
      )}
    </>
  );
};

SelectTransfer.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  configs: PropTypes.shape({
    allow_personal_bank_account_transfers: PropTypes.bool.isRequired,
    allow_person_to_person_transfers: PropTypes.bool.isRequired,
    allow_account_to_account_transfer: PropTypes.bool.isRequired,
    allow_child_cards: PropTypes.bool.isRequired,
    allow_interac_pull_transfers: PropTypes.bool.isRequired,
    allow_external_transfers_v2: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SelectTransfer;
