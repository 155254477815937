import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Input, ActionBar, SubmitButton } from 'shared-components';
import { numberDollarsToCents } from 'shared-components/src/utils/currency';
import InputGroup from '../../../../../layouts/InputGroup';
import InputRow from '../../../../../layouts/InputRow';
import Form from '../../../../../layouts/Form';
import EditableDropdown from '../../../components/EditableDropDown/EditableDropDown';

const ExternalTransferDirectsendPushForm = ({
  disableSubmit,
  externalAccounts,
  setPushParams,
  setShowConfirmModal,
  page,
  deleteExternalAccount,
  editExternalAccount,
}) => {
  const intl = useIntl();
  const externalAccountsRef = useRef(null);

  const [amountInput, setAmountInput] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();

    setPushParams({
      amount: numberDollarsToCents(amountInput),
      transfer_type: 'direct_send_transaction',
      external_account_id: parseInt(externalAccountsRef.current.value, 10),
    });
    setShowConfirmModal(true);
  };

  const handleSelect = option => {
    setSelectedOption(option);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputRow>
        <InputGroup
          label={intl.messages[`${page}-form-select-external-account-label`]}
          labelFor="externalAccounts"
          page={page}
          required
        >
          <EditableDropdown
            options={externalAccounts}
            selectedOption={selectedOption}
            onSelect={handleSelect}
            onEdit={editExternalAccount}
            onDelete={deleteExternalAccount}
            page={page}
          />
        </InputGroup>
        <InputGroup
          required
          label={intl.messages[`${page}-amount-label`]}
          labelFor="amount"
          page={page}
        >
          <Input
            name="amount"
            pattern="[0-9\.]"
            title="Please enter a valid amount"
            placeholder="00.00"
            type="number"
            step="0.01"
            value={amountInput}
            onChange={e => setAmountInput(e.target.value)}
            required
          />
        </InputGroup>
      </InputRow>
      <ActionBar>
        <SubmitButton disabled={disableSubmit} />
      </ActionBar>
    </Form>
  );
};

ExternalTransferDirectsendPushForm.propTypes = {
  externalAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      vanity_identifier: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ).isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  setPushParams: PropTypes.func.isRequired,
  setShowConfirmModal: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  deleteExternalAccount: PropTypes.func.isRequired,
  editExternalAccount: PropTypes.func.isRequired,
};

export default ExternalTransferDirectsendPushForm;
